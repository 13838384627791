
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import Select from 'react-select';
import API_ASISTENCIA from "../../componentes/config/apisAsistencia_Variables";
import QrCamera from "./Modal/QrCamera";
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import '../CSS/App.css'
import 'datatables.net';
import 'datatables.net-bs5';
import ModalRadios from "./Modal/ModalRadios";
import ModalMotos from "./Modal/ModalMotos";
import ModalBicicletas from "./Modal/ModalBicicletas";
import ModalCamionetas from "./Modal/ModalCamionetas";
import ModalPuestosFijos from "./Modal/ModalPuestosFijos";
//Mensaje de error 505 para todas las consultas
const error500 = "Error en el servidor"
//Obtener la fecha ,día ,mes y año
const fecha = new Date();
const dia = fecha.getDate();
const mes = fecha.getMonth();
const año = fecha.getFullYear();
function RegistroRecursos() {
  //Declaración de Constantes para el manejo de estados y valores
  const [datos, setDatos] = useState(null);
  console.log(datos)
  const [documento, setDocumento] = useState("");
  const [showModalBuscador, setShowModalBuscador] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const nombre = Cookies.get("nombre")
  const apellidopat = Cookies.get("apellidopat")
  const apellidomat = Cookies.get("apellidomat")
  const dnilogin = Cookies.get("dnilogin");
  const [horaActual, setHoraActual] = useState("");
  const [funciones, setFunciones] = useState([]);
  const [selectedFuncion, setSelectedFuncion] = useState('');
  const [serenos, setSerenos] = useState([])
  const [selectedRadio, setSelectedRadio] = useState(null)
  const [selectedMovilidad, setSelectedMovilidad] = useState(null)
  const [selectedPuestoFijo, setSelectedPuestoFijo] = useState(null)
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [dnivalido, setDniValido] = useState(false)
  const [showModalRadios, setShowModalRadios] = useState(false);
  const [showModalMotos, setShowModalMotos] = useState(false);
  const [showModalBicicletas, setShowModalBicicletas] = useState(false);
  const [showModalCamionetas, setShowModalCamionetas] = useState(false);
  const [showModalPuestosFijos, setShowModalPuestosFijos] = useState(false);
  const [selectedCataCHSecpolicial, setSelectedCataCHSecpolicial] = useState(null);
  const [selectedCataCHJurpolicial, setSelectedCataCHJurpolicial] = useState(null);
  const [idRadio, setIdRadio] = useState('')
  const [idMovilidad, setIdMovilidad] = useState('')
  const [idPuestoFijo, setIdPuestoFijo] = useState('')
  const [tipoRecurso, setTipoRecurso] = useState(false);
  console.log(idRadio)
  console.log(idMovilidad)
  console.log(idPuestoFijo)

  const meses =
    ['enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ];
  const fechahoy = `${dia < 10 ? '0' + dia : dia} de ${meses[mes]} del ${año}`;
  const [showToast, setShowToast] = useState(false);

  const handleDocumentoChange = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      setDocumento(inputValue);
    } else {
    }
  }
  const handleClose = () => {
    setDatos(null);
    setErrorMessage("");
    setShowToast(false);
    setDatos('')
    setSelectedFuncion('')
    setSelectedPuestoFijo('')
    setSelectedMovilidad('')
    setSelectedRadio('')
    setSelectedCataCHSecpolicial('')
    setSelectedCataCHJurpolicial('')
  };
  console.log(handleClose)
  const handleCloseListar = () => {
    setShowModalBuscador(false)
  }
  const deleteRadio = () => {
    setSelectedRadio('')
  }
  const deletedMovilidad = () => {
    setSelectedMovilidad('')
  }
  const deletedPuesto = () => {
    setSelectedPuestoFijo('')
  }
  const handleShowBuscador = () => setShowModalBuscador(true);

  const obtenerDatos = async () => {
    try {
      const respuesta = await axios.get(API_ASISTENCIA['serenosPorDNI'](documento));
      setDatos(respuesta.data);
      setDniValido(true)
      const dni = respuesta.data.SERE_P_chDNI;
      const horaActual = new Date();
      const horaConsultaFormato = horaActual.toLocaleTimeString();
      Cookies.set("horaConsulta", horaConsultaFormato);
      Cookies.set("dnisereno", dni);
    } catch (error) {
      setDniValido(false)
      setErrorMessage(`${documento === "" ? "Debe ingresar un documento" : "!No se encontró el documento¡"}`);
      setShowToast(true);
    }
  };

  const guardarLocalmente = (datosAsistencia) => {
    const registrosPendientes = JSON.parse(localStorage.getItem('registrosPendientes')) || [];
    registrosPendientes.push(datosAsistencia);
    localStorage.setItem('registrosPendientes', JSON.stringify(registrosPendientes));
  };

  const enviarDatosPendientes = async () => {
    const registrosPendientes = JSON.parse(localStorage.getItem('registrosPendientes')) || [];
    const registrosEnviados = JSON.parse(localStorage.getItem('registrosEnviados')) || [];

    for (const datos of registrosPendientes) {
      try {
        const respuestaPost = await axios.post(API_ASISTENCIA['postasistencia'], datos);
        console.log('Respuesta de la API:', respuestaPost.data);
        registrosEnviados.push(datos);
        localStorage.setItem('registrosEnviados', JSON.stringify(registrosEnviados));
      } catch (error) {
        console.error('Error al enviar los datos:', error);
      }
    }
    localStorage.removeItem('registrosPendientes');
  };
  //POST MOVIL DEL REGISTRO EN BLOQUE
  const registroRecursoMovil = async (tipoAsistencia) => {
    const dni = Cookies.get("dnisereno");
    const horaGuardada = Cookies.get("horaConsulta");
    const dniregistrante = Cookies.get("dnilogin");
    //validación de datos que se enviarán al metodo POST
    const datosAsistencia = {
      sere_p_chdni: dni,
      asis_chhor: horaGuardada,
      asis_chentsal: tipoAsistencia,
      asis_choapp: "appwebasis",
      asis_chfun: datos.SERE_chCargo ? datos.SERE_chCargo : selectedFuncion,
      asis_chtipreg: tipoRecurso,
      asis_chdesact: "",
      Cata_CH_Codcatarec: selectedMovilidad.Cata_CH_Codcatarec,
      ASIS_CH_TIPO_BI: 'B',
      asis_CH_Jurpolicial: selectedCataCHJurpolicial,
      asis_CH_Secpolicial: selectedCataCHSecpolicial,
      cata_CH_Cod_rolserv: '',
      cata_IN_IDPK_rolserv: null,
      ASIS_IN_idpk_respondoc: dniregistrante,
      cata_CH_IDPK_rolservsr: null,
      asis_in_lat: latitud,
      asis_in_lon: longitud
    };
    try {

      //SOLICITUD POST
      const respuestaPost = await axios.post(API_ASISTENCIA['postasistencia'], datosAsistencia);
      console.log('Se subieron los datos correctamente:', respuestaPost.data);

      setIdMovilidad(respuestaPost.data.asis_p_inid)
      //La consulta es exitosa , se confirma el post.
      return true; // Devuelve true si el registro fue exitoso

    } catch (error) {
      //Si no es exitosa , se muestra un error
      setErrorMessage(error500, "(pi011e_a)");
      setShowToast(true);
      guardarLocalmente(datosAsistencia);
      return false; // Devuelve false si el registro fue exitoso

    }
  };
  const registroRecursoRadio = async (tipoAsistencia) => {
    const dni = Cookies.get("dnisereno");
    const horaGuardada = Cookies.get("horaConsulta");
    const dniregistrante = Cookies.get("dnilogin");
    //validación de datos que se enviarán al metodo POST
    const datosAsistencia = {
      sere_p_chdni: dni,
      asis_chhor: horaGuardada,
      asis_chentsal: tipoAsistencia,
      asis_choapp: "appwebasis",
      asis_chfun: datos.SERE_chCargo ? datos.SERE_chCargo : selectedFuncion,
      asis_chtipreg: "Rad",
      asis_chdesact: "",
      Cata_CH_Codcatarec: selectedRadio.Cata_CH_Codcatarec,
      ASIS_CH_TIPO_BI: 'B',
      asis_CH_Jurpolicial: selectedCataCHJurpolicial,
      asis_CH_Secpolicial: selectedCataCHSecpolicial,
      cata_CH_Cod_rolserv: '',
      cata_IN_IDPK_rolserv: null,
      ASIS_IN_idpk_respondoc: dniregistrante,
      cata_CH_IDPK_rolservsr: null,
      asis_in_lat: latitud,
      asis_in_lon: longitud
    };
    try {

      //SOLICITUD POST
      const respuestaPost = await axios.post(API_ASISTENCIA['postasistencia'], datosAsistencia);
      console.log('Se subieron los datos correctamente:', respuestaPost.data);
      setIdRadio(respuestaPost.data.asis_p_inid)

      //La consulta es exitosa , se confirma el post.
      return true; // Devuelve true si el registro fue exitoso

    } catch (error) {
      //Si no es exitosa , se muestra un error
      setErrorMessage(error500, "(pi011e_a)");
      setShowToast(true);
      guardarLocalmente(datosAsistencia);
      return false; // Devuelve false si el registro fue exitoso

    }
  };
  const registroRecursoPuesto = async (tipoAsistencia) => {
    const dni = Cookies.get("dnisereno");
    const horaGuardada = Cookies.get("horaConsulta");
    const dniregistrante = Cookies.get("dnilogin");
    //validación de datos que se enviarán al metodo POST
    const datosAsistencia = {
      sere_p_chdni: dni,
      asis_chhor: horaGuardada,
      asis_chentsal: tipoAsistencia,
      asis_choapp: "appwebasis",
      asis_chfun: datos.SERE_chCargo ? datos.SERE_chCargo : selectedFuncion,
      asis_chtipreg: "PT",
      asis_chdesact: "",
      Cata_CH_Codcatarec: selectedPuestoFijo.Cata_CH_Codcatarec,
      ASIS_CH_TIPO_BI: 'B',
      asis_CH_Jurpolicial: selectedCataCHJurpolicial,
      asis_CH_Secpolicial: selectedCataCHSecpolicial,
      cata_CH_Cod_rolserv: '',
      cata_IN_IDPK_rolserv: null,
      ASIS_IN_idpk_respondoc: dniregistrante,
      cata_CH_IDPK_rolservsr: null,
      asis_in_lat: latitud,
      asis_in_lon: longitud
    };
    try {

      //SOLICITUD POST
      const respuestaPost = await axios.post(API_ASISTENCIA['postasistencia'], datosAsistencia);
      //La consulta es exitosa , se confirma el post.
      console.log('Se subieron los datos correctamente:', respuestaPost.data);
      setIdPuestoFijo(respuestaPost.data.asis_p_inid)
      return true; // Devuelve true si el registro fue exitoso
    } catch (error) {
      //Si no es exitosa , se muestra un error
      setErrorMessage(error500, "(pi011e_a)");
      setShowToast(true);
      guardarLocalmente(datosAsistencia);
      return false;

    }
  };

  const handleBLoqueRegister = async (tipoAsistencia) => {
    const dni = Cookies.get("dnisereno");
    const result = await Swal.fire({
      icon: 'question',
      title: "¿Está seguro de registrar los siguientes datos de Recurso?",
      html:
        `
        <h5><strong>${datos.SERE_chNOM} ${datos.SERE_chAPEPAT} ${datos.SERE_chAPEMAT}</strong></h2>
        <p>DNI: <strong>${dni}</strong></p>
        <p>Función:<strong> ${datos.SERE_chCargo ? datos.SERE_chCargo : selectedFuncion}</strong></p>
          ${selectedRadio?  `<p>Codigo de Radio: ${selectedRadio.cata_CH_Nomcatarec} </p>`:''} 
                    ${selectedMovilidad?  `<p>Codigo de Radio: ${selectedMovilidad.cata_CH_Nomcatarec} </p>`:''}     
          ${selectedPuestoFijo?  `<p>Codigo de Radio: ${selectedPuestoFijo.cata_CH_Nomcatarec} </p>`:''}        
      `,
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar'
    });
    if (!longitud && !latitud) {
      Swal.fire({
        icon: "error",
        title: "Active su ubicación",
        text: "Active su ubicación y vuelva a cargar la página.",
      });
      return;
    }
    if (result.isConfirmed) {
      try {
        const resultados = await Promise.all([
          selectedPuestoFijo ? registroRecursoPuesto(tipoAsistencia) : Promise.resolve(true),
          selectedRadio ? registroRecursoRadio(tipoAsistencia) : Promise.resolve(true),
          selectedMovilidad ? registroRecursoMovil(tipoAsistencia) : Promise.resolve(true)
        ]);

        const [confirmacionRadio, confirmacionPF, confirmacionMovil] = resultados;

        if (!confirmacionRadio ||  !confirmacionPF || (selectedMovilidad && !confirmacionMovil)) {
          Swal.fire({
            icon: 'error',
            title: 'Error al registrar la asistencia',
            text: 'Debe Completar todos los campos',
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "REGISTRO EXITOSO",
            text: "¡REGISTRE SU FOTO!",
            allowOutsideClick: false
          }).then(() => {
            setErrorMessage(`La asistencia se registrò correctamente`);
            setTimeout(() => {
              setErrorMessage('Ahora debe registrar su foto');
              setShowToast(true);
            }, 2000);
            handleClose()
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un error al registrar la asistencia.',
        });
      }
    } else {
      setErrorMessage(`El usuario canceló el registro`);
      setShowToast(true);
    }
  };

  useEffect(() => {
    const updateHoraActual = () => {
      const currentHora = new Date().toLocaleTimeString();
      setHoraActual(currentHora);
    };
    const intervalId = setInterval(updateHoraActual, 1000);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    const obtenerUbicacion = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLatitud(latitude);
            setLongitud(longitude);
            console.log("la ubicacion es")
          },
          (error) => {
            setErrorMessage(`${error500} para obtener la ubicación`, error);
            setShowToast(true);
          }
        );
      } else {
        console.error("La geolocalización no es compatible con este navegador.");
      }
    };
    const verificarConexion = async () => {
      try {
        const response = await fetch('https://jsonplaceholder.typicode.com/posts/1', { method: 'HEAD' });

        if (response.ok) {
          console.log('Hay conexión a Internet.');
          enviarDatosPendientes();
          return true;
        } else {
          console.log('No hay conexión a Internet.');
          return false;
        }
      } catch (error) {
        console.log('No hay conexión a Internet.');
        return false;
      }
    };
    const fetchFunciones = async () => {
      try {
        const response = await axios.get(API_ASISTENCIA['funciones']);
        setFunciones(response.data);
      } catch (error) {
        setErrorMessage(`${error500} para obtener las funciones (api50g_par)`);
        setShowToast(true);
      }
    };
    const fetchUsuarios = async () => {
      try {
        const response = await axios.get(API_ASISTENCIA['usuarios']);
        setSerenos(response.data);
      } catch (error) {
        setErrorMessage(`${error500} para obtener los usuarios (pi120_M_MA)`);
        setShowToast(true);
      }
    };
    obtenerUbicacion()
    verificarConexion()
    fetchFunciones();

    fetchUsuarios();
  }, []);


  const handleLogout = () => {
    Cookies.remove("rol");
    Cookies.remove("nombre");
    Cookies.remove("apellidopat");
    Cookies.remove("apellidomat");
    Cookies.remove("dnilogin");
    Cookies.remove("miip");
    Cookies.remove("codigorol");
    Cookies.remove("idrol");
    Cookies.remove("horario");
    window.location.replace('/');
  };

  return (
    <div className="app">
      <h3 className="horaactual" style={{ fontWeight: '500', color: 'rgb(162, 162, 162)', fontSize: '15px', marginTop: '10px' }}>{fechahoy}</h3>
      <h3 className="horaactual" style={{ fontWeight: '500', color: 'rgb(162, 162, 162)', fontSize: '15px' }}>{horaActual}</h3>
      <header style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-50px' }}>
        <img src={require("../../componentes/logoerlan.png")} style={{ width: '15%', height: '80px', objectFit: 'cover' }} className='logoerlan' />
      </header>
      <nav >
        <a className="atras" href="/principal" style={{ cursor: 'pointer' }}><i className="fa-solid fa-arrow-left"></i> Atras</a>
        <a className="salir" onClick={handleLogout} style={{ cursor: 'pointer' }}><i className="fa-solid fa-users-slash"></i> Cerrar Sesión</a>
      </nav>
      <div className="app-container">
        <h1 className="tituloregistro"><i className="fa-solid fa-clipboard-check"></i> Asignación de Recursos</h1>

        <div style={{ textAlign: 'left', width: '100%' }}>
          <p className="datos-usuarioizq" style={{ textTransform: 'capitalize', fontSize: '15px', color: 'rgb(68, 68, 69)' }}>Usuario: {nombre} {apellidopat} {apellidomat} - {dnilogin}
          </p>
        </div>

        {showToast && (
          <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>
            <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-header bg-primary text-white">
                <strong className="me-auto">Mensaje del Sistema</strong>
                <small>{horaActual}</small>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowToast(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="toast-body">
                {errorMessage}
              </div>
            </div>
          </div>
        )}
        <div
          className="row" style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
        >
          <div className="col-lg-6 col-xl-6 col-sm-6" style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <p className='titulo-RegistroDoc' style={{ width: '100%', textAlign: 'center', fontWeight: 'bold', color: '#025E73' }}>Búsqueda por Documento {' '} <i className="fa-solid fa-id-card"></i></p>
            <div className="inputboton" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', width: '100%' }}>
              <input
                type="text"
                id="placaInput"
                value={documento}
                className="form-control"
                onChange={handleDocumentoChange}
                style={{ border: '1px solid rgb(22, 73, 200)', textAlign: 'center', width: '50%', fontSize: '15px', borderRadius: '2px' }}
                placeholder="Ingrese un DNI"
              />
              <button className="btn btn-success botonregistrar"
                type="button"
                onClick={obtenerDatos}
                disabled={!documento}
                style={{ width: '50%', fontSize: '15px', borderRadius: '2px' }}>
                Registrar {' '}
                <i className="fa-solid fa-pencil"></i>
              </button>
            </div>
            <div className="botonesabajo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '10px', width: '100%' }}>
              <button
                className="btn btn-success botonlistarnombres"
                type="button"
                onClick={handleShowBuscador}
                title="Buscar Usuario por Nombre"
                style={{ width: '50%', fontSize: '15px', borderRadius: '2px' }}
              >
                Listar{' '}
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>
            {datos && (
              <div className="cardDatos" style={{ width: '100%', padding: '20px 20px', margin: '15px 5px', borderRadius: '5px', boxShadow: '0px 0px 8px 0px  rgb(2, 94, 115)' }}>
                <div className="datosestaticosnombres" style={{ display: 'flex', justifyContent: 'left', gap: '15px' }}>
                  <p className="nombresapellidosmodal">Nombres: <strong >{datos.SERE_chNOM} {datos.SERE_chAPEPAT} {datos.SERE_chAPEMAT}</strong></p>
                  <p className="nombresapellidosmodal">DNI: <strong>{datos.SERE_P_chDNI}</strong></p>

                </div>
                <div className="datosestaticos" style={{ display: 'flex', alignItems: 'center' }}>
                  {datos.SERE_chCargo ? <p className="nombresapellidosmodal">Función: <strong>{datos.SERE_chCargo}</strong></p>
                    : <>
                      <p style={{ marginRight: '20px' }} className="size12RV">Función:</p>
                      <Select
                        className="w-100 SelectFuncion"
                        options={funciones.map(funcion => ({ value: funcion.per_CH_nomParam, label: funcion.per_CH_nomParam }))}
                        onChange={(selectedOption) => setSelectedFuncion(selectedOption ? selectedOption.value : null)}
                        isSearchable
                        placeholder="Selecciona una Función"
                      /></>}
                </div>
                <div className="datosestaticos" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginBottom: '10px' }}>
                  {selectedRadio ? <p className="nombresapellidosmodal" style={{ margin: '5px' }}>RADIO: <strong >{selectedRadio.cata_CH_Nomcatarec} ( {selectedRadio.Cata_CH_Codcatarec} ) <i class="fa-solid fa-walkie-talkie" style={{color:'#198754'}}></i></strong> <button style={{ color: 'white', backgroundColor: ' red', fontSize: '15px', marginLeft: '15px', border: 'none', padding: ' 0 8px', borderRadius: '3px' }} onClick={deleteRadio}><i class="fa-regular fa-trash-can"></i> </button> </p> : ''}
                  {selectedMovilidad ? <p className="nombresapellidosmodal" style={{ margin: '5px' }}>{tipoRecurso === 'Mot' ? 'MOTO' : tipoRecurso === 'Camio' ? 'CAMIONETA' : 'BICICLETA'}: <strong > {selectedMovilidad.cata_CH_Nomcatarec} ( {selectedMovilidad.Cata_CH_Codcatarec} ) {tipoRecurso === 'Mot' ? <i class="fa-solid fa-motorcycle"  style={{color:'#025E73'}}></i> : tipoRecurso === 'Camio' ? <i class="fa-solid fa-car" style={{color:'#025E73'}}></i> : <i class="fa-solid fa-bicycle"  style={{color:'#025E73'}}></i>} </strong> <button style={{ color: 'white', backgroundColor: ' red', fontSize: '15px', marginLeft: '15px', border: 'none', padding: ' 0 8px', borderRadius: '3px' }} onClick={deletedMovilidad}><i class="fa-regular fa-trash-can"></i> </button> </p> : ''}
                  {selectedPuestoFijo ? <p className="nombresapellidosmodal" style={{ margin: '5px' }}>PUESTO FIJO: <strong >{selectedPuestoFijo.cata_CH_Nomcatarec} <i class="fa-solid fa-map-location-dot" style={{color:'#a09607'}}></i></strong> <button style={{ color: 'white', backgroundColor: ' red', fontSize: '15px', marginLeft: '15px', border: 'none', padding: ' 0 8px', borderRadius: '3px' }} onClick={deletedPuesto}><i class="fa-regular fa-trash-can"></i> </button> </p> : ''}


                </div>
                <div className="botonesrecursos" style={{ display: 'flex', gap: '5px', margin: '1rem' }}>
                  <Button variant="success" style={{ width: '20%', fontSize: '15px', display:`${selectedRadio ?'none' : 'initial'}` }} onClick={() => setShowModalRadios(true)}>
                    Radios <i class="fa-solid fa-walkie-talkie"></i>
                  </Button>
                  <Button variant="dark" style={{ width: '20%', fontSize: '15px',display:`${selectedMovilidad ?'none' : 'initial'}` }} onClick={() => setShowModalMotos(true)}>
                    Motos <i class="fa-solid fa-motorcycle"></i>
                  </Button>
                  <Button variant="dark" style={{ width: '20%', fontSize: '15px',display:`${selectedMovilidad ?'none' : 'initial'}` }} onClick={() => setShowModalCamionetas(true)}>
                    Camionetas 
                  </Button>
                  <Button variant="dark" style={{ width: '20%', fontSize: '15px',display:`${selectedMovilidad ?'none' : 'initial'}` }} onClick={() => setShowModalBicicletas(true)}  >
                    Bicicletas <i class="fa-solid fa-bicycle"></i>
                  </Button>
                  <Button variant="warning" style={{ width: '20%', fontSize: '15px',display:`${selectedPuestoFijo ?'none' : 'initial'}` }} onClick={() => setShowModalPuestosFijos(true)}>
                    Puesto Fijo <i class="fa-solid fa-map-location-dot"></i>
                  </Button>
                </div>
                <div className="botonesmodal ">
                  <Button
                    variant="success"
                    className="btnseleccionar"
                    onClick={() => {
                        handleBLoqueRegister("R");
                    }}
                    disabled={(!datos.SERE_chCargo && !selectedFuncion)}
                  >
                    Registrar Asignación
                  </Button>
                  <Button variant="danger" onClick={handleClose} className="btnseleccionar">
                    Cerrar
                  </Button>
                </div>
              </div>
            )}

            <Modal show={showModalBuscador} onHide={handleCloseListar} centered>
              <Modal.Header closeButton className="bg-success text-center">
                <Modal.Title style={{ "color": "#fff" }}>Buscar por Apellidos y Nombres </Modal.Title>
              </Modal.Header>
              <Modal.Body className="tabladedatos">
                <div className="mb-3">
                  <label className="form-label">Nombre</label>
                  <Select
                    className="w-100 SelectRecurso"
                    options={serenos.map(sereno => ({ value: sereno.SERE_P_chDNI, label: `( ${sereno.SERE_P_chDNI} ) ${sereno.SERE_APELLNOM}` }))}
                    onChange={(selectedOption) => setDocumento(selectedOption ? selectedOption.value : null)}
                    isSearchable
                    placeholder="Buscar Personal"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button variant="success" onClick={obtenerDatos} disabled={!documento}>
                  Registrar
                </Button>

                <Button variant="danger" onClick={handleCloseListar}>
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="col-lg-6 col-xl-6 col-sm-6">
            <QrCamera
              documentousuario={documento}
              documentoresponsable={dnilogin}
              dnivalido={dnivalido}
              idRadio={idRadio }
              idMovilidad={idMovilidad }
              idPuestoFijo={ idPuestoFijo}
              tipoRecurso = {tipoRecurso}
            />
            <ModalRadios show={showModalRadios} close={() => setShowModalRadios(false)} setSelectedRadio={setSelectedRadio} />
            <ModalMotos show={showModalMotos} close={() => setShowModalMotos(false)} setSelectedMoto={setSelectedMovilidad} setTipoRecurso={setTipoRecurso} />
            <ModalCamionetas show={showModalCamionetas} close={() => setShowModalCamionetas(false)} setSelectedCamioneta={setSelectedMovilidad} setTipoRecurso={setTipoRecurso} />
            <ModalBicicletas show={showModalBicicletas} close={() => setShowModalBicicletas(false)} setSelectedBicicleta={setSelectedMovilidad} setTipoRecurso={setTipoRecurso} />
            <ModalPuestosFijos show={showModalPuestosFijos} close={() => setShowModalPuestosFijos(false)} setSelectedPuestoFijo={setSelectedPuestoFijo} setSelectedCataCHSecpolicial={setSelectedCataCHSecpolicial} setSelectedCataCHJurpolicial={setSelectedCataCHJurpolicial} />
          </div>
        </div>

      </div>
    </div>
  );
}
export default RegistroRecursos;