import {Navigate, Route, Routes} from 'react-router-dom'
import { RoutesPrivate } from './ProtectedRoute'

import RegistroRecursos from '../Pages/Asistencia/RegistroRecursos'




const PrivateRoutes = () => {
  return (
    <Routes>
        <Route path={RoutesPrivate.RECURSOS} element={<RegistroRecursos />} />
        <Route path='*' element={<RegistroRecursos />} />
        </Routes>
  )
}
export {PrivateRoutes}
