import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import App from './App';

import{ PublicRoutes, RoutesPrivate } from './Security/ProtectedRoute';
import Cookies from 'js-cookie';
import { PrivateRoutes } from './Security/PrivateRoutes';

function AppRoutes() {
  const isAuthenticated = () => {
    const token = Cookies.get('dnilogin');
    return !!token;
  };
  return (
    <Routes>

      {isAuthenticated() ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to={RoutesPrivate.RECURSOS} />} />
            </>
              ) : (

            <>
              <Route path='/*' element={<App />} />
              <Route path='*' element={<Navigate to={PublicRoutes.AUTH} />} />
            </>
            )}
    </Routes> 
  );
}

function AppContainer() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export { AppContainer as Routes };