import React, { useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import API_ASISTENCIA from '../../../componentes/config/apisAsistencia_Variables';
import axios from 'axios';
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
const ModalRadios = ({ show, close , setSelectedRadio }) => {
    const [radios, setRadios] = useState([])
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!loading) {
          // Destruir el DataTable existente antes de inicializar uno nuevo
          const table = $("#tablaRadios").DataTable();
          if ($.fn.DataTable.isDataTable(table)) {
            table.destroy();
          }
          $.extend(true, $.fn.dataTable.defaults, {
            language: {
              sProcessing: "Procesando...",
              sLengthMenu: "Mostrar _MENU_ registros",
              sZeroRecords: "No se encontraron resultados",
              sEmptyTable: "Ningún dato disponible en esta tabla",
              sInfo:
                "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
              sInfoEmpty:
                "Mostrando registros del 0 al 0 de un total de 0 registros",
              sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
              sInfoPostFix: "",
              sSearch: "Buscar:",
              sUrl: "",
              sInfoThousands: ",",
              sLoadingRecords: "Cargando...",
              oPaginate: {
                sFirst: "Primero",
                sLast: "Último",
                sNext: "Siguiente",
                sPrevious: "Anterior",
              },
              oAria: {
                sSortAscending:
                  ": Activar para ordenar la columna de manera ascendente",
                sSortDescending:
                  ": Activar para ordenar la columna de manera descendente",
              },
              buttons: {
                copy: "Copiar",
                colvis: "Visibilidad",
              },
            },
          });
    
          // Inicializar el DataTable
          $("#tablaRadios").DataTable();
        }
      }, [loading, show]);
      const handleSeleccionarRadio = (accion) => {
        setSelectedRadio(accion);
        close(); 
      };
    const obtenerRadios = async () => {
        try {
          setLoading(true);
          const respuesta = await axios.get(
            API_ASISTENCIA["radios"]
          );
          setRadios(respuesta.data);
          console.log(respuesta.data);
        } catch (error) {
          console.error("Error al obtener los datos:", error);
        } finally {
          setLoading(false);
        }
      };
      useEffect(() => {
        setLoading(true);
        obtenerRadios();
      }, []);
  return (
    <Modal show={show} onHide={close} centered dialogClassName="modal-xl" className='font-responsive'>
                      <Modal.Header closeButton className="bg-success text-center">
                <Modal.Title style={{ "color": "#fff" }}>Radios </Modal.Title>
              </Modal.Header>
              <Modal.Body className="tabladedatos">
              {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
          </div>
        ) : (

              <div className="table-responsive">
                <table
                  id="tablaRadios"
                  className="table table-striped text-center"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th className='text-center'>Código</th>
                      <th className='text-center'>Serie</th>
                      <th className='text-center'>Seleccionar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {radios !== null &&
                      radios.map((index) => (
                        <tr >
                          <td>{index.Cata_CH_Codcatarec}</td>
                          <td>{index.cata_CH_Serie2catarec}</td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-success btn-sm btnseleccionar"
                              title="Seleccionar Acción"
                              style={{fontSize:'10px',width:'50%',margin:'auto'}}
                              onClick={() => handleSeleccionarRadio(index)}
                            >
                              Seleccionar
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
        )}
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-end">


                <Button variant="danger" onClick={close}>
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
  )
}

export default ModalRadios
